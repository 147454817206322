import React from 'react';
import "./Buttons.css"

const Buttons = () => {
    return (
        <section id='buttons'>
            <div className="container">
                <div className="wrapper">
                    <div className="box">
                        <p>Steps to Recover</p>
                        <div className="buttons d-grid justify-content-between align-items-center">
                            <button className='cmn-btn'>Fill Form</button>
                            <button className='cmn-btn'>Submit</button>
                            <button className='cmn-btn'>Processing</button>
                            <button className='cmn-btn'>Recieve</button>


                  </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Buttons;