import React from 'react';
import "./Footer.css"

const Footer = () => {
    return (
        <footer id='footer'>
           <div className="footer-container mx-auto">

            
           <div>
           <div className='d-flex footer-contents justify-content-between'>
                <div>
                    <a href="/">
                    <img width="150" src="/images/logoW.png" alt="" />
                    
                    </a>
                    <p className='mb-0 text-center' style={{marginTop:"79px"}}></p>
                </div>

            </div>
            <p style={{color : `white`, fontStyle : `italic`}}>This portal is approved on behalf of the Joint Provisional Liquidators ("JPLs") of FTX Digital Markets, Ltd. (In Provisional Liquidation) (“FDM”) who are officers of The Supreme Court of The Bahamas (the "Court") and who are tasked with the duty of safeguarding the business, property and affairs of FDM.  The JPLs act as agents of FDM, without personal liability, and any request contained within this correspondence is being made solely as it pertains to FDM and in enabling furtherance of the duties conferred on the JPLs by the Court.

</p>
           </div>

            <p className='footer-bottom-text text-center'>2022 @ FTX, All Rights Reserved</p>
           </div>

           
        </footer>
    );
};

export default Footer;