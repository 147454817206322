import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./Navbar.css"
// import menuBar from "/images/logo.png"
// import close from "/images/xmark-solid.svg"

const Navbar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const menus = [
        {
            name: "Menu",
            link: "#"
        },
    ]
    return (
        <div>
            <nav id='navbar' className='d-none d-md-block'>
                <div className="container">
                    <div >
                        <div className='d-flex justify-content-between '>
                            <div className="logo">
                                <img width="150" src="/images/logo.png" alt="" />
                            </div>
                            <div className='d-flex align-items-center'>
                                <ul className='d-flex m-0'>
                                    {
                                        menus.map((menu, index) => <li
                                            key={index}>
                                            <a className='text-decoration-none' href={menu.link}>{menu.name}</a>
                                        </li>)
                                    }
                                </ul>
                                <button className='cmn-btn'>GET STARTED</button>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>

            {/* mobile navbar */}
            <nav id='mobile-navbar' className=' d-md-none'>
                <div className="container">
                    <div >
                        
                        <div className=' d-md-none'>
                            <div className='d-flex justify-content-between  align-items-center '>
                                <div className="logo">
                                    <img src="/images/logo.png" alt="" />
                                </div>
                                <button id='mobile-menu-toggoler' className='outline-0' onClick={handleShow}>
                                    <img src="/images/menu.png" width="24px" alt="" />
                                </button>
                            </div>

                            <Offcanvas show={show} onHide={handleClose} placement="end">
                                <Offcanvas.Header >
                                    <Offcanvas.Title>Menus</Offcanvas.Title>
                                    <button id='mobile-menu-toggoler' className='outline-0 border-0' onClick={handleClose}>
                                        <img src="/images/cancel.png" width="24px" alt="" />
                                    </button>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div id='mobile-menu' className=''>
                                        <ul className=' m-0'>
                                            {
                                                menus.map((menu, index) => <li
                                                    key={index}>
                                                    <a className='text-decoration-none' href={menu.link}>{menu.name}</a>
                                                </li>)
                                            }
                                        </ul>
                                        <button className='cmn-btn'>GET STARTED</button>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>


                        </div>

                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;