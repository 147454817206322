import './App.css';
import Buttons from './components/Buttons/Buttons';
import Form from './components/Form/Form';
import Footer from './components/Global/Footer/Footer';
import Navbar from './components/Global/Navbar/Navbar';
import Hero from './components/HeroArea/Hero';
import Table from './components/Table/Table';

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <main>
        <Buttons />
        <Table />
        <Form/>
      </main>
      <Footer/>
    </>
  );
}

export default App;
