import React from 'react';
import { Table } from 'react-bootstrap';
import "./Table.css";


const MainTable = () => {
    return (
        <section id='table'>
            <div className="container">
                <div className='contents'>
                    <h1 className="table-first-title">
                    FTX Digital Markets Ltd. (In Provisional Liquidation)

                    </h1>
                    <p className=' first-p'>FTX Digital, which principally operates in The Bahamas, is registered to provide an exchange between digital assets and traditional “fiat” currency, alongside an exchange between one or more different forms of digital assets.
</p>

<br></br>

<p className=' first-p'>The JPLs have been appointed to identify, secure and safeguard the assets of the Company and in doing so protect the interests of investors and creditors. Certain other FTX entities filed voluntary petitions for chapter 11 in the United States Bankruptcy Court for the District of Delaware (the “Chapter 11 Bankruptcy Proceedings”). FTX Digital is not included in this group and therefore is not subject to the Chapter 11 Bankruptcy Proceedings.

</p>
<br></br>

<p className=' first-p'>This website is for the exclusive benefit of FTX Digital stakeholders, such as the customers and employees and will be populated with relevant information and updates from the JPLs from time to time relating to FTX Digital and the Bahamian Liquidation Proceedings. No party should look to this website for information relating to the Chapter 11 Bankruptcy Proceedings

</p>
                    <h1 className="second-title">
                    UPDATE ON CLAIMS PROCESS


                    </h1>
                    <p className=' second-paragraph'>The JPLs invite customers of FTX Digital and third party claim holders with potential claims against FTX Digital to register their contact details with us. The JPLs recognise that customers may not know if they are customers or creditors of FTX Digital, or other entities in the FTX group. If you think you may have a claim against FTX Digital, please submit your details via the claims portal for consideration by the JPLs at the appropriate time.

</p>

                    <p className='table-top-paragraph'>As the process moves forward we are likely to follow the below stages:</p>




                    {/* table-box-data */}
                    <div className='table-containers'>
                        <Table className='m-0 w-full'>
                            <thead>
                                <tr className="tr-border-bottom">
                                    <th className='th-border-right' style={{ width: "84px", textAlign: "center" }}>STAGE</th>
                                    <th className='th-border-right' style={{ width: "94px", textAlign: "center" }}>PROCESS</th>
                                    <th className='th-padding-left' >OUTLINE</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr className="tr-border-bottom">
                                    <td className='th-border-right' style={{ textAlign: "center" }}>1</td>
                                    <td className='th-border-right'>Claims registration	</td>
                                    <td >Registration of creditor and customer account details
</td>

                                </tr>
                                <tr className="tr-border-bottom">
                                    <td className='th-border-right' style={{ textAlign: "center" }}>2</td>
                                    <td className='th-border-right'>Address Validation	</td>
                                    <td>Validate address for Asset transfer (Stage 5)
</td>

                                </tr>
                                <tr className="tr-border-bottom">
                                    <td className='th-border-right' style={{ textAlign: "center" }}>3</td>
                                    <td className='th-border-right'>Identity verification	</td>
                                    <td >Process requiring additional identification documentation from customers and creditors
</td>

                                </tr>
                                <tr className="tr-border-bottom">
                                    <td className='th-border-right' style={{ textAlign: "center" }}>4</td>
                                    <td className='th-border-right'>Balance acceptance	</td>
                                    <td >Provides customers and creditors with the opportunity to view and confirm the balance owing from the books and records of FTX Digital
</td>

                                </tr>
                                <tr>
                                    <td className='th-border-right' style={{ textAlign: "center" }}>5</td>
                                    <td className='th-border-right' >Asset transfer	</td>
                                    <td >Dividend/payment to creditors and customers (if sufficient value recovered)
</td>

                                </tr>
                            </tbody>
                        </Table>

                    </div>


                </div>

            </div>

        </section>
    );
};

export default MainTable;