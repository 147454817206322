import React from 'react';
import "./Hero.css"

const Hero = () => {
    return (
        <header id='hero-area'>
            <div className="container">
                <p className='first-p'>JRay Partners LLP</p>
                <h1 className='head-title'>Joint Provisional FTX Liquidator.</h1>
                <p className='second-p'>Create Claim and Recover Your FTX Assets.</p>
                <div className="buttons">
                    <button>Enquiries</button>
                    <button style={{display: 'none'}}>Mail us</button>
                </div>
            </div>
        </header>
    );
};

export default Hero;