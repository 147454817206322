import React from 'react';
import './Form.css'


const Form = () => {
    const [formStep, setFormStep] = React.useState(0)
    return (
        <div id='form'>
            <div className="container">
                <h1 className='form-heading'>Fill our form to get started</h1>


                <div className="form-contents">
                    <h2 className='form-title'>Let us know about you</h2>
                    <p>All informations submitted to this form is encrypted and never shared with any thirdparty</p>


                   { formStep === 0 && <form >
                        <p>Full Name *</p>
                        <input type="text" required placeholder='Enter your full name'/>
                        <p>Email Address *</p>
                        <input type="text" required placeholder='Enter your FTX email address'/>
                        <p>Telephone Number *</p>
                        <input type="text" required placeholder='JANE DOE'/>
                  
                      
                        <button onClick={()=> {
                            setFormStep(1)
                        }}className='next-btn d-flex align-items-center ms-auto'><span>Next</span> <img src="/images/arrow.png" alt="" /></button>
                    </form>}


                    { formStep === 1 &&<form >
                        <p>Country of Origin*</p>
                        <input type="text" required placeholder='Country of Origin'/>
                        <p>Estimated Value of Total Asset *</p>
                        <input type="text" required placeholder='Enter your FTX email address'/>
                    
                      
                        <button className='next-btn d-flex align-items-center ms-auto'><span>Next</span> <img src="/images/arrow.png" alt="" /></button>
                    </form>}
                </div>
            </div>
        </div>
    );
};

export default Form;